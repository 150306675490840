
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import OwnBuilding from "@/views/reusable/buy-insurance/house-insurance/partials/OwnBuilding.vue";
import RentBuilding from "@/views/reusable/buy-insurance/house-insurance/partials/RentBuilding.vue";
import { Field, ErrorMessage } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "building-house-insurance",
  components: {
    Field,
    ErrorMessage,
    OwnBuilding,
    RentBuilding,
    TotalPremiumWidget,
  },
  props: { publicId: String },
  data() {
    return {
      buildingChoice: false,
      buildingChoice2: false,
      result: {
        householderPublicId: "",
        commercialCartItemPublicId: "",
        hasHouseholderCartItem: true || (false as boolean),
        hasCommercialCartItem: true || (false as boolean),
      },
    };
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Building", ["Householders Insurance "]);
    });
  },
  created() {
    this.hasExistingCartPackages();

    //Set page title
    document.title =
      "Building Householders Insurance  | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/BUI/new`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    ownerSelection() {
      this.buildingChoice = true;
      this.buildingChoice2 = false;
      variables.resetPremium();
    },

    rentSelection() {
      this.buildingChoice2 = true;
      this.buildingChoice = false;
      variables.resetPremium();
    },
  },
});
